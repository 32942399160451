<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ `${isAdd ? $t('actions.add') : $t('actions.edit')} ${ $tc('purge_alarm.title', 1)}`}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('purge_alarm.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tenant -->
          <validation-provider
            #default="validationContext"
            name="tenant"
          >
            <b-form-group
              :label="$tc('tenants.title', 1)"
              label-for="tenants"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.tenants"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenant"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type alarms -->
          <validation-provider
            #default="validationContext"
            name="alarms_types"
          >
            <b-form-group
              :label="$tc('types_alarms.title', 2)"
              label-for="alarms_types"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.alarms_types"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeAlarmOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="alarms_types"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- alarm_status_in -->
          <validation-provider
            #default="validationContext"
            name="alarm_status_in"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('purge_alarm.alarm_status_in')"
              label-for="alarm_status_in"
              :state="getValidationState(validationContext)"
            >

              <v-select
                v-model="itemData.alarm_status_in"
                :options="optionsIn"
                :clearable="false"
                input-id="alarm_status_in"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- alarm_status_out -->
          <validation-provider
            #default="validationContext"
            name="alarm_status_out"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('purge_alarm.alarm_status_out')"
              label-for="status"
              :state="getValidationState(validationContext)"
            >

              <v-select
                v-model="itemData.alarm_status_out"
                :options="optionsOut"
                :clearable="false"
                input-id="alarm_status_out"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- comment -->
          <validation-provider
            #default="validationContext"
            name="comment"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('purge_alarm.comment')"
              label-for="comment"
            >
              <b-form-input
                id="comment"
                v-model="itemData.comment"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- protocol -->
          <validation-provider
            #default="validationContext"
            name="protocol"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('purge_alarm.protocol')"
              label-for="protocol"
            >
              <b-form-input
                id="protocol"
                v-model="itemData.protocol"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- timeout -->
          <validation-provider
            #default="validationContext"
            name="timeout"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('purge_alarm.timeout')"
              label-for="timeout"
            >
              <b-form-input
                id="timeout"
                v-model="itemData.timeout"
                :state="getValidationState(validationContext)"
                type="number"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t('actions.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t('actions.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
    typeAlarmOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      optionsIn: [
        "waiting",
        "attending",
      ],
      optionsOut: [
        "attending",
        "attended",
        "canceled"
      ]
    }
  },
  setup(props, { emit }) {
    const blankData = {
      name: '',
      tenants: [],
      alarms_types: [],
      alarm_status_in: 'waiting',
      alarm_status_out: 'canceled',
      comment: '',
      protocol: '',
      timeout: 15,
    }

    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankData)))

    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      let req = itemData.value

      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch('alarm_purge/add', req)
      } else {
        const data = {
          id: itemData.value.id,
          params: req,
        }

        dispatch = store.dispatch('alarm_purge/edit', data)
      }

      dispatch
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-sidebar-active', false)
        })
        .catch((response) => {
          if (response.response.status == 422) {
            let string = ``
            Object.entries(response.response.data.data).forEach(entry => {
              const [key, value] = entry
              string += `${key} - ${value}<br>`
            })
            toast({
              component: ToastificationContent,
              props: {
                title: `${response.response.data.message}`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: string
              },
            },{
              timeout: 10000
            }
            )
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetData)

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit

        itemData.value.alarms_types = props.itemEdit.alarms_types ? props.itemEdit.alarms_types.map(alarmType => (alarmType.id)) : null
        itemData.value.tenants = props.itemEdit.tenants ? props.itemEdit.tenants.map(tenant => (tenant.id)) : null
      }
    }

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}
</style>
