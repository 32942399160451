import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('tenant/getAll')

  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  store.dispatch('type_alarm/getAll')

  const typeAlarmOptions = computed(() => store.getters['type_alarm/getSelectedItems'])
  const typeAlarmFilter = ref(null)

  const tableColumns = [
    { label: 'purge_alarm.name', key: 'name', sortable: true },
    { label: 'tenants.title', key: 'tenants', sortable: false,
      formatter: (value, key, item) => {
        if(item.tenants) return [...new Set(item.tenants.map(item => { return item.name}))].join(', ')
          return ""
      }
    },
    { label: 'types_alarms.title', key: 'alarms_types', sortable: false,
      formatter: (value, key, item) => {
        if(item.alarms_types) return [...new Set(item.alarms_types.map(item => { return item.description}))].join(', ')
          return ""
      }
    },
    { label: 'purge_alarm.alarm_status_in', key: 'alarm_status_in', sortable: true },
    { label: 'purge_alarm.alarm_status_out', key: 'alarm_status_out', sortable: true },
    { label: 'purge_alarm.comment', key: 'comment', sortable: true },
    { label: 'purge_alarm.protocol', key: 'protocol', sortable: true },
    { label: 'purge_alarm.timeout', key: 'timeout', sortable: true },
    {
      label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned',
    },
    {
      label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned',
    },
  ]

  if (ability.can('update', 'PurgeAlarm') || ability.can('delete', 'PurgeAlarm')) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const action = ref('list')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('alarm_purge/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      tenants: tenantFilter.value,
      alarms_types: typeAlarmFilter.value,
      action: action.value
    })
      .then(response => {
        if (action.value == 'export') {
          action.value = 'list'
        } else {
          callback(response.data)
          totalList.value = response.meta.total
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ tenantFilter, typeAlarmFilter, currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    refetchData,

    tenantOptions,
    tenantFilter,
    typeAlarmOptions,
    typeAlarmFilter,
    action,
  }
}
